<template>
  <div class="card card-custom gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">レンタル項目一覧</h3>
      </div>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <label>ホールレンタル</label>
        </div>
      </div>
      <hr />
      <el-table
          :fit="true"
          border
          style="width: 100%; text-align: center;"
          :data="this.listRental.room">
        <el-table-column
            prop="name"
            label="項目"
            align="center">
          <template slot-scope="scope">
            <div class="text-left">
              <span class="text-right">{{scope.row.name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="price"
            label="価格/時間"
            align="center">
          <template slot-scope="scope">
            <div class="text-right">
              <span class="text-right">{{scope.row.price_format}} 円</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <b-button @click="showModalDetailRoom(scope.row.id)" class="pl-15 pr-15" type="primary">価格の変更</b-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- list equipment -->

      <div class="row custom_row">
        <div class="col-md-2">
          <label>スタート、機材</label>
        </div>
      </div>
      <hr />
      <el-table
          id="table-equipment"
          :data="listRental.listEquipment"
          border
          style="width: 100%"
          class="custom_table"
      >
        <el-table-column
            label="カテゴリー"
            min-width="80"
            align="center">
          <template slot-scope="scope">
            <div class="text-left">
                <span>
                  {{scope.row.category.name}}
                 </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="項目"
            min-width="150"
            align="center">
          <template slot-scope="scope">
            <div class="text-left">
                <span>
                  {{scope.row.name}}
                 </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="price"
            label="価格/回"
            min-width="50"
            align="center">
          <template slot-scope="scope">
            <div class="text-right">
              <span>{{scope.row.price_format}} 円</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="quantity"
            label="数量"
            min-width="30"
            align="center">
        </el-table-column>
        <el-table-column min-width="50" align="center">
          <template slot-scope="scope">
            <b-button @click="editEquipment(scope.row.id,scope.row.name)" class="pl-15 pr-15" type="primary">詳細</b-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="icon icon-plus">
        <i @click="showModal()" class="fas fa-plus-square"></i>
      </div>
    </div>
    <!-- modal equipment -->
    <el-dialog width="70%" :visible.sync="dialogEquipment" class="custom-el-dialog-031" id="add-equipment" hide-header hide-footer centered>
      <div>
        <div class="modal-header text-center">
          <h3 style="width: 100%">{{title_popup}}</h3>
        </div>
        <div class="row" v-loading="loadingModal">
          <div class="modal-body">
            <el-form ref="form" :rules="rules" :model="form" class="form_el_custom">
              <label class="el-form-item__label text-left">カテゴリー <span class="text-danger">*</span></label>
              <el-form-item prop="category_id" class="item-title-form" label-width="11%">
                <el-select
                    class="custom-el-select"
                    v-model="form.category_id"
                    placeholder="カテゴリーを選択してください">
                  <el-option
                      v-for="item in listCategory"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="parent" label-width="0px">
                <el-col :md="16">
                  <label class="el-form-item__label text-right">項目 <span class="text-danger">*</span></label>
                  <el-form-item prop="name" class="form_name_custom" label-width="17%">
                    <el-input
                        :maxlength="100"
                        v-model="form.name"
                        placeholder="商品名を入力してください"
                        @blur="form.name = form.name.trim()"
                        >
                    </el-input>
                  </el-form-item>
                  <label class="el-form-item__label text-right-custom">※項目の数量に「０」を入力すると、ユーザー側の予約画面に項目が非示されます。</label>
                </el-col>
                <el-col :md="8">
                  <label class="el-form-item__label text-center">数量 <span class="text-danger">*</span></label>
                  <el-form-item prop="quantity" label-width="30%">
                    <el-input
                        :maxlength="2"
                        onkeydown="javascript: return event.keyCode === 8
                        ||event.keyCode === 46 || event.keyCode === 190 ? true : !isNaN(Number(event.key))"
                        @blur="form.quantity = form.quantity.trim()"
                        v-model="form.quantity"
                        style="width: 55%;"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <!-- item 3 -->
              <el-form-item prop="parent" label-width="0px">
                <el-col :md="8">
                  <label class="el-form-item__label text-right">価格 <span class="text-danger">*</span></label>
                  <el-form-item prop="price" class="custom_number" label-width="34%">
                    <el-input
                        class="custom_el_input_31"
                        :maxlength="6"
                        onkeydown="javascript: return event.keyCode === 8
                        ||event.keyCode === 46 || event.keyCode === 190 ? true : !isNaN(Number(event.key))"
                        @blur="form.price = form.price.trim()"
                        v-model="form.price">間</el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="2">
                  <el-form-item class="text-center custom-item-price">
                    <span>円</span>
                  </el-form-item>
                </el-col>
                <el-col :md="5">
                  <label class="el-form-item__label text-left">From</label>
                  <el-form-item prop="time_from" label-width="40%">
                    <el-input
                        maxLength="3"
                        onkeydown="javascript: return event.keyCode === 8
                      ||event.keyCode === 46 || event.keyCode === 190 ? true : !isNaN(Number(event.key))"
                        v-model="form.time_from"
                        @blur="form.time_from = form.time_from.trim()"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="5">
                  <label class="el-form-item__label" style="width: 30%;">To</label>
                  <el-form-item prop="time_to" label-width="40%">
                    <el-input
                        maxLength="3"
                        @blur="form.time_to = form.time_to.trim()"
                        onkeydown="javascript: return event.keyCode === 8
                      ||event.keyCode === 46 || event.keyCode === 190 ? true : !isNaN(Number(event.key))"
                        v-model="form.time_to">時間</el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label-width="15%">
                    <span>時間</span>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <!-- item 4  -->
              <el-form-item class="custom_header_item_equipment" label-width="16px">
                <el-col :md="15">
                  <el-form-item  class="text-center custom-label" prop="category">
                    <span>機材名</span>
                  </el-form-item>
                </el-col>
                <el-col :md="6">
                  <el-form-item  class="text-center custom-label" prop="category">
                    <span>数量</span>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item
                  label-width="0px"
                  v-for="(item, index) in arrayDes"
                  :key="index"
                  prop="parent"
              >
                <el-col :md="15">
                  <el-form-item :prop="'equipment_name'+index" class="custom-input error-31">
                    <el-input
                        class="custom_el_input_31 input_31"
                        @blur="arrayDes[index].equipment_name = arrayDes[index].equipment_name.trim()"
                        :maxlength="100" v-model="arrayDes[index].equipment_name"
                        placeholder="機材名を入力してください"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="6">
                  <el-form-item :prop="'equipment_quantity'+index" class="custom-input">
                    <el-input
                        class="input_quantity"
                        :maxlength="2"
                        @blur="arrayDes[index].equipment_quantity = arrayDes[index].equipment_quantity.trim().replace(/^0+/, '')"
                        onkeydown="javascript: return event.keyCode === 8
                      ||event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                        v-model="arrayDes[index].equipment_quantity"
                        placeholder="数量を入力してください"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="2">
                  <el-form-item class="custom-input">
                    <i @click="removeItem(index)" class="fas fa-trash-alt icon-delete"></i>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item label-width="0px">
                <el-col :md="16">
                  <el-form-item class="custom-input">
                    <div class="icon">
                      <i @click.prevent="addNewItem()" class="fas fa-plus-square"></i>
                    </div>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-form>
          </div>
          <!-- end row -->
        </div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button style="width: 120px;" class="btn-cancel-order" @click="closeModal()">
            閉じる
          </b-button>
          <b-button style="width: 120px;" type="primary" @click="addEquipment()">
            保存
          </b-button>
        </div>
      </div>
    </el-dialog>
    <!-- end modal equipment -->

    <!-- modal room -->
    <el-dialog class="custom-el-dialog-033" :visible.sync="dialogRoom" v-loading="loadingPopup" width="30%" center>
      <div>
        <div class="modal-header text-center">
          <h3 style="width: 100%">ホールレンタル</h3>
        </div>
        <div class="modal-body content_popup"  >
          <el-form class="custom-col el-form-custom" ref="detailRoom" :model="detailRoom">
            <el-col :md="6">
              <label class="el-form-item__label text-left">価格/時間 <span class="text-danger">*</span></label>
            </el-col>
            <el-form-item prop="roomPrice">
              <el-input
                  maxlength="6"
                  @blur="detailRoom.roomPrice = detailRoom.roomPrice.trim()"
                  onkeydown="javascript: return event.keyCode === 8
                  ||event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                  v-model="detailRoom.roomPrice"
              >
              </el-input>
            </el-form-item>
            <el-col :md="4">
              <el-form-item label-width="40%">
                <span>円</span>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button style="width: 120px;" class="btn-back" @click="closeModalDetailRoom()">
            閉じる
          </b-button>
          <b-button style="width: 120px;"  type="primary" @click="editRoom()">
            保存
          </b-button>
        </div>
      </div>
    </el-dialog>
    <!-- end dialog room -->

  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { GET_LIST_RENTAL, GET_LIST_CATEGORY, CREATE_EQUIPMENT, GET_DETAIL_EQUIPMENT, UPDATE_ROOM } from "@/core/services/store/listRental.module";
import * as constants from "@/core/config/constant";

export default {
  name: "list-rental",
  data() {
    return {
      data: {},
      loading: false,
      detailRoom: {
        roomId: '',
        roomPrice: '',
      },
      form: {
        id: '',
        category_id: '',
        name: '',
        quantity: '',
        price: '',
        time_from: '',
        time_to: '',
        description: [],
      },
      loadingModal: false,
      loadingPopup: false,
      arrayDes: [
        {
          equipment_name: '',
          equipment_quantity: '',
        }
      ],
      rules: {
      },
      title_popup: '',
      dialogRoom: false,
      dialogEquipment: false,
    };

  },

  computed: {
    ...mapGetters(["listRental", "listCategory", "detailEquipment"]),
    queryParam() {
      return this.$route.query;
    },

  },

  watch: {
    async queryParam() {
      await this.getListRental();
    },

  },
  async created() {
    await this.getListRental();
    await this.getListCategory();
    this.detailRoom.roomPrice = this.listRental.room[0].price ?? '';
  },
  methods: {
    ...mapActions([GET_LIST_RENTAL, GET_LIST_CATEGORY, CREATE_EQUIPMENT, GET_DETAIL_EQUIPMENT, UPDATE_ROOM]),
    showModal(){
      this.title_popup = '項目の追加';
      this.resetForm();
      if (this.$refs["form"]){
        this.turnOffErrorMessage("form");
      }
      this.dialogEquipment = true;
    },
    closeModal(){
      this.dialogEquipment = false
    },
    showModalDetailRoom(id){
      if (this.$refs["detailRoom"]){
        this.turnOffErrorMessage("detailRoom");
      }
      this.detailRoom.roomId = id;
      this.detailRoom.roomPrice = this.listRental.room[0].price ?? '';
      this.dialogRoom = true;
    },
    closeModalDetailRoom(){
      this.resetFormRoom();
      this.dialogRoom = false;
    },
    dataDefault(){
      return {
        equipment_name: '',
        equipment_quantity: '',
      }
    },
    addNewItem(){
      this.arrayDes.push(this.dataDefault());
    },
    removeItem(index){
      this.arrayDes.splice(index, 1);
    },
    resetForm() {
      this.form = {
        id: '',
        category_id: '',
        name: '',
        quantity: '',
        price: '',
        time_from: '',
        time_to: '',
        description: [],
      }
      this.arrayDes = [
        {
          equipment_name: '',
          equipment_quantity: '',
        }
      ]
    },
    resetFormRoom() {
      this.detailRoom = {
        roomId: '',
        roomPrice: '',
      }
    },
    addEquipment(){
      const convertFullToHalf = str => str.replace(/[！-～]/g, r => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
      this.form.name = typeof this.form.name == 'string' ? convertFullToHalf(this.form.name) : this.form.name;
      this.form.quantity = typeof this.form.quantity == 'string' ? convertFullToHalf(this.form.quantity) : this.form.quantity;
      this.form.price = typeof this.form.price == 'string' ? convertFullToHalf(this.form.price) : this.form.price;
      this.form.time_to = typeof this.form.time_to == 'string' ? convertFullToHalf(this.form.time_to) : this.form.time_to;
      this.form.time_from = typeof this.form.time_from == 'string' ? convertFullToHalf(this.form.time_from) : this.form.time_from;
      this.form.description = this.arrayDes.map(item =>
      {
        item.equipment_name = item.equipment_name ?  convertFullToHalf(item.equipment_name) : item.equipment_name;
        item.equipment_quantity = item.equipment_quantity ?  convertFullToHalf(item.equipment_quantity) : item.equipment_quantity;
      });

      this.form.description = this.arrayDes;
      this.$refs["form"].validate((valid) => {
        if(valid){
          this.loadingModal = true;
          this.turnOffErrorMessage("form");
          this.createEquipment(this.form)
          .then((data) => {
            if (data.data.code === constants.STATUS_CODE.SUCCESS){
              this.dialogEquipment = false;
              this.getListRental();
              this.getListCategory();
              this.notifySuccess(this.$t("success"));
              this.resetForm();
              this.loadingModal = false;
            } else if (data.data.code === constants.STATUS_CODE.BAD_REQUEST) {
              this.loadingModal = false
              this.showErrorMessage(data.data.data, "form");
            }
        }).catch(e => {
            this.loadingModal = false
            if(e.code  === constants.STATUS_CODE.BAD_REQUEST){
              this.showErrorMessage(e.data, "form");
            }else{
              this.notifyError(this.$t("errorServer"))
            }
          })
        }
      })
    },
    getEquipment(){
      if (this.$refs["form"]){
        this.turnOffErrorMessage("form");
      }
      this.form.id = this.detailEquipment.id ?? ''
      this.form.category_id = this.detailEquipment.category_id ?? ''
      this.form.name = this.detailEquipment.name ?? ''
      this.form.quantity = this.detailEquipment.quantity ?? ''
      this.form.price = this.detailEquipment.price ?? ''
      this.form.time_to = this.detailEquipment.time_to ?? ''
      this.form.time_from = this.detailEquipment.time_from ?? ''
      this.arrayDes = this.detailEquipment.description ?? ''
    },
    async editEquipment(id,name) {
      await this.getDetailEquipment(id);
      this.title_popup = name;
      this.getEquipment();
      this.dialogEquipment = true;
    },
    editRoom() {
      this.$refs["detailRoom"].validate((valid) => {
        if(valid){
          this.loadingPopup = true;
          this.turnOffErrorMessage("detailRoom");
          this.updateRoom(this.detailRoom)
          .then(response => {
            if (response.data.code === constants.STATUS_CODE.SUCCESS){
              this.loadingPopup = false;
              this.getListRental();
              this.dialogRoom = false;
              this.notifySuccess(this.$t("success"));
            } else if (response.code === constants.STATUS_CODE.BAD_REQUEST) {
              this.loadingPopup = false;
              this.showErrorMessage(response.data, "detailRoom");
            } else {
              this.loadingPopup = false;
              this.notifyError(this.$t("errorServer"))
            }
          }).catch(e => {
              this.loadingPopup = false
            if(e.code  === constants.STATUS_CODE.BAD_REQUEST){
              this.showErrorMessage(e.data, "detailRoom");
            }else{
              this.notifyError(this.$t("errorServer"))
            }
          });
        }
      })
    }
  },
};
</script>

<style scoped src="@/assets/css/rental.css">
</style>
<style lang="scss">
.el-form-item.error-31.is-error {
  .el-form-item__error {
    padding-left: 15px !important;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
.form_name_custom {
   margin-left: 4% !important;
}
.custom_number .el-form-item__content {
  margin-left: 41% !important;
}
  .el-dialog {
    width: 80%;
  }
  .text-right-custom {
    margin-left: 90px !important;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 320px) {
  .form_name_custom .el-form-item__content {
    margin-left: 16%;
    padding-left: 2px;
  }
  .custom_number .el-form-item__content {
    margin-left: 33%;
  }
  .el-dialog {
    width: 80%;
  }
  .text-right-custom {
    margin-left: 90px !important;
    white-space: nowrap;
  }
}

</style>
